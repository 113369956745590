<template>
  <div>
    <v-container class="container--fluid grid-list-md text-center">
      <v-row align="center" justify="center">
                <v-img src="/img/401.png" alt="Unauthorized" contain max-height="80vh" />
            </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized',
};
</script>
